import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useMunicipality } from 'providers/MunicipalityProvider';
import { SchoolService } from 'services/schoolService';

type Program = {
  programName: string;
  programType: string;
  programCode: string;
};

type ProgramContextType = {
  programs: Program[];
  loading: boolean;
};

const ProgramContext = createContext<ProgramContextType | null>(null);

export const ProgramProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { municipality } = useMunicipality();
  const { data, loading, error } = useQuery(SchoolService.GET_GYMNASIUM_PROGRAMS, {
    variables: {
      municipalityIds: [municipality.code],
    },
    skip: !municipality.code,
  });

  const [programs, setPrograms] = useState<Program[]>([]);

  useEffect(() => {
    if (data?.programs) {
      setPrograms(data.programs);
    }
  }, [data]);

  const value = { programs, loading };

  return <ProgramContext.Provider value={value}>{children}</ProgramContext.Provider>;
};

export const usePrograms = () => {
  const context = useContext(ProgramContext);
  if (!context) {
    throw new Error('usePrograms must be used within a ProgramProvider');
  }
  return context;
};